import React from 'react'
import constructionImage from "../../Assets/construction.jpg"

const WellnessResourcesPage = () => {
  return (
    <div>
    <h4 style={{textAlign:"center"}}>Page Under Construction</h4>
      <img src={constructionImage} alt='' style={{height:"90vh",width:"100%"}}/>
    </div>
  )
}

export default WellnessResourcesPage